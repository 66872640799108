<template>
  <div>
    <v-tabs
      class="custom-navbar"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      v-if="isAuthenticated"
      v-model="selected_tab"
    >
      <template v-for="item in !isGPV && !isStaff ? mainTabs : gpv_staff_Tabs">
        <v-tab :key="item.key">
          <router-link :to="item.link">
            <template v-if="isGPVorStaff ? item.key === 0 : item.key === 1">
              <template v-if="globalState.my_incidence_counts.holiday > 0">
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template
              v-else-if="isGPVorStaff ? item.key === 1 : item.key === 3"
            >
              <template v-if="globalState.my_incidence_counts.leaves > 0">
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template
              v-else-if="isGPVorStaff ? item.key === -1 : item.key === 2"
            >
              <template
                v-if="globalState.team_pending_approval_counts.holiday > 0"
              >
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template
              v-else-if="isGPVorStaff ? item.key === -1 : item.key === 4"
            >
              <template
                v-if="globalState.team_pending_approval_counts.leaves > 0"
              >
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template v-else>{{ item.label }}</template>
          </router-link>
        </v-tab>
      </template>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "holidayleaves",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isSPV",
      "isGPV",
      "isBrand",
      "isStaff",
      "user",
      "globalState",
    ]),
    isGPVorStaff() {
      return !(!this.isGPV && !this.isStaff);
    },
    calendarTab() {
      return {
        label: this.$t("Calendar"),
        key: 0,
        link: "/holidayleaves/calendar",
      };
    },
    myHolidayTab() {
      return {
        label: this.$t("My Holidays"),
        key: 1,
        link: "/holidayleaves/holiday/my",
      };
    },
    myTeamHolidayTab() {
      return {
        label: this.$t("My Team Holidays"),
        key: 2,
        link: "/holidayleaves/holiday/team",
      };
    },
    myLeavesTab() {
      return {
        label: this.$t("My Leaves"),
        key: 3,
        link: "/holidayleaves/leaves/my",
      };
    },
    myTeamLeavesTab() {
      return {
        label: this.$t("My Team Leaves"),
        key: 4,
        link: "/holidayleaves/leaves/team",
      };
    },
    mainTabs() {
      let tabs = [
        this.calendarTab,
        this.myHolidayTab,
        this.myTeamHolidayTab,
        this.myLeavesTab,
        this.myTeamLeavesTab,
      ];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    gpv_staff_Tabs() {
      let tabs = [this.myHolidayTab, this.myLeavesTab];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    selected_tab: {
      set(selected_tab) {},
      get() {
        let selected_tab_arr = this.gpv_staff_Tabs;
        if (!this.isGPV && !this.isStaff) {
          selected_tab_arr = this.mainTabs;
        }
        console.log("this.selected_tab_arr - ", selected_tab_arr);
        console.log(this.$route.fullPath);
        let route_fullpath = this.$route.fullPath;
        let paths = this.$route.fullPath.split("/");
        let selected_tab = selected_tab_arr.filter(
          (tab_item) => this.$route.fullPath === tab_item.link
        );
        if (selected_tab.length === 0) {
          selected_tab = selected_tab_arr.filter((tab_item) =>
            this.$route.fullPath.includes(tab_item.link)
          );
        }
        if (selected_tab.length > 0) {
          return selected_tab[0].key;
        } else {
          return selected_tab_arr[0].key;
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch("auth/getGlobalState");
  },
};
</script>

<style></style>
